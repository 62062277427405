<template>
  <v-card class="pa-4" flat>
    <v-row class="mb-4" no-gutters align="center">
      <FilterSchoolYear :withInitial="true" notMultiple @handler="getFilter" />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        :label="$t('app.search')"
        class="my-2"
        outlined
        dense
        hide-details
        clearable
        style="max-width: 250px"
        prepend-inner-icon="mdi-magnify"
      />
    </v-row>

    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      :search="search"
      class="elevation-0"
    >
      <template v-slot:item.lesson="{ item }">
        <span>{{ item.lesson }}</span>
        <span v-if="item.sub" class="ml-2">{{ item.sub }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-row no-gutters align="center">
          <v-btn fab depressed x-small color="primary" @click="toDetail(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import FilterSchoolYear from "../../components/filterSchooYear";
import { mapGetters } from "vuex";
import { getSubjectsList } from "@/api/admin/academic/subjects";

import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

export default {
  metaInfo: {
    title: i18n.t("routes.journal"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    FilterSchoolYear
  },
  computed: mapGetters(["g_master_data_siswa"]),
  data() {
    return {
      loading: false,
      search: "",
      queryData: {
        school_year: "",
        grade: "",
        class: ""
      },
      param: {
        school_year: [],
        classroom: []
      },
      tableHeaders: [
        {
          text: i18n.t("app.subjects"),
          value: "lesson"
        },
        {
          text: i18n.t("app.action"),
          value: "action",
          width: 150
        }
      ],
      dataTable: []
    };
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "JournalDetail",
        params: { lesson: item.id_lesson, ...this.queryData }
      });
    },
    getFilter(res) {
      switch (res.type) {
        case "school_year":
          this.queryData.school_year = res.data.id;
          this.param.school_year = [res.data.id];
          break;
        case "select_grade":
          this.queryData.grade = res.data;
          break;
        case "select_class":
          this.queryData.class = res.data;
          this.param.classroom = [res.data];
          this.initData();
          break;
        default:
          break;
      }
    },
    initData() {
      this.loading = true;
      this.dataTable = [];
      getSubjectsList({
        filter: { ...this.param }
      })
        .then(res => {
          if (res.data.code) {
            this.dataTable = res.data.data;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.initData();
    }
  }
};
</script>

<style></style>
