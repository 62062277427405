<template>
  <component :is="currentRole" />
</template>

<script>
import { mapGetters } from "vuex";

import JournalTeacher from "./JournalTeacher";
import JournalAdmin from "./JournalAdmin";

export default {
  name: "Journal",
  metaInfo: {
    title: "Journal",
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    JournalTeacher,
    JournalAdmin
  },
  computed: {
    ...mapGetters(["g_user"])
  },
  data() {
    return {
      currentRole: "JournalAdmin"
    };
  },
  created() {
    if (this.g_user.role_type == "ADMIN") {
      this.currentRole = "JournalAdmin";
    } else if (this.g_user.role_type == "TEACHER") {
      this.currentRole = "JournalTeacher";
    }
  }
};
</script>
