<template>
  <v-card class="pa-4" :class="g_darkmode ? 'black' : 'grey lighten-5'" flat>
    <v-card :class="g_darkmode ? 'black' : 'white'" flat class="mb-4 pa-4">
      <v-row no-gutters>
        <FilterSchoolYear
          :withInitial="true"
          notMultiple
          @handler="getFilter"
        />
        <v-spacer></v-spacer>
        <div style="max-width: 200px">
          <DatePicker
            :label="$t('journal.date')"
            :value="queryData.date"
            outlined
            dense
            hideDetails
            @getValue="
              v => {
                queryData.date = v;
                this.initData();
              }
            "
          />
        </div>
      </v-row>
    </v-card>

    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      class="elevation-0"
      show-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-2">
          <v-card
            v-for="(journal, idx) in item.journals"
            :key="idx"
            class="pa-4"
            :class="item.journals.length > 1 && 'mb-4'"
            outlined
          >
            <div class="mb-2 font-weight-bold">
              <b>{{ journal.learning_material }}</b>
            </div>
            <v-row class="my-4" v-if="journal.file && journal.file.length > 0">
              <v-divider class="my-2"></v-divider>
              <v-col
                v-for="(row, index) in journal.file"
                :key="index"
                cols="12"
              >
                <v-chip
                  color="primary"
                  close
                  class="mr-2"
                  @click="openLink(row.file)"
                >
                  {{ row.file.replace(/^.*[\\\/]/, "") }}
                </v-chip>
              </v-col>
            </v-row>
            <div v-if="journal.description">
              <v-divider class="my-2"></v-divider>
              <span v-html="journal.description"></span>
            </div>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getJournalForAdmin } from "@/api/admin/absent/journal";
import { mapGetters } from "vuex";
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

import FilterSchoolYear from "../../components/filterSchooYear";
import DatePicker from "@/components/DatePicker";

let typingTimer;
const doneTypingInterval = 750;

export default {
  metaInfo: {
    title: i18n.t("routes.journal"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    DatePicker,
    FilterSchoolYear
  },
  computed: mapGetters(["g_master_data_siswa", "g_user", "g_darkmode"]),
  data() {
    return {
      detailLesson: {
        class_grade: "",
        class_name: "",
        lesson: "",
        semester: ""
      },
      loading: false,
      tableHeaders: [
        {
          text: i18n.t("journal.table.subject"),
          value: "lesson_name"
        },
        {
          text: i18n.t("journal.table.supporting_teacher"),
          value: "person_name"
        }
        // {
        //   text: i18n.t("app.action"),
        //   value: "action",
        //   width: 150
        // }
      ],
      queryData: {
        page: 1,
        search: "",
        date: "",
        classroom: this.$route.params.class
      },
      dataTable: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData.date = moment().format("YYYY-MM-DD");
    });
  },
  methods: {
    openLink: url => window.open(url, "_blank"),
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.page = 1;
        this.getData();
      }, doneTypingInterval);
    },
    getFilter(res) {
      switch (res.type) {
        case "select_class":
          this.queryData.classroom = res.data;
          this.initData();
          break;
        default:
          break;
      }
    },
    initData() {
      if (this.queryData.classroom && this.queryData.date) {
        this.loading = true;
        this.dataTable = [];
        getJournalForAdmin(this.queryData)
          .then(res => {
            if (res.data.code) {
              this.dataTable = res.data.data;
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      }
    }
  }
};
</script>

<style></style>
